html,
body {
  height: 100%;
  width: 100%;

  div {
    ::-webkit-scrollbar {
      width: 4px;
    }

    ::-webkit-scrollbar-track {
      background: #fff;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #d0d3d6;
    }
  }
}

html {
  scroll-padding-top: 115px;
}

#root {
  height: 100%;

  display: flex;
  flex-direction: column;

  &.isLoading {
    cursor: wait;

    &:before {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10000;
      background-color: rgba(#fff, 0.5);
    }
  }
}

// tinymcy editor popup checkbox
.tox-checkbox {
  &__icons {
    box-shadow: none !important;
  }
}

[data-block-scroll="true"] {
  .global-sticky {
    right: var(--scrollbar-compensation);
  }

  .fixed-sidebar {
    right: var(--scrollbar-compensation);
  }
}

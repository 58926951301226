@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?8emr5i');
  src:  url('fonts/icomoon.eot?8emr5i#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?8emr5i') format('truetype'),
    url('fonts/icomoon.woff?8emr5i') format('woff'),
    url('fonts/icomoon.svg?8emr5i#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-tablet1:before {
  content: "\ea68";
}
.icon-add-above:before {
  content: "\ea69";
}
.icon-add-below:before {
  content: "\ea6a";
}
.icon-desktop:before {
  content: "\ea6b";
}
.icon-mobile:before {
  content: "\ea6c";
}
.icon-page-builder1:before {
  content: "\ea6d";
}
.icon-sidebarright:before {
  content: "\ea6e";
}
.icon-selectbox:before {
  content: "\ea66";
}
.icon-content:before {
  content: "\ea60";
}
.icon-chevrons-closed:before {
  content: "\ea0c";
}
.icon-chevrons-open:before {
  content: "\ea0d";
}
.icon-drag-in-drop:before {
  content: "\ea61";
}
.icon-dragindrop:before {
  content: "\ea65";
}
.icon-page-builder:before {
  content: "\ea62";
}
.icon-preview:before {
  content: "\ea63";
}
.icon-widget:before {
  content: "\ea64";
}
.icon-access_logo:before {
  content: "\ea0b";
}
.icon-check-default:before {
  content: "\ea0e";
}
.icon-close-default:before {
  content: "\ea0f";
}
.icon-comment:before {
  content: "\ea10";
}
.icon-dashboard:before {
  content: "\ea11";
}
.icon-delete:before {
  content: "\ea12";
}
.icon-favorite_border-default:before {
  content: "\ea13";
}
.icon-favorite-default:before {
  content: "\ea14";
}
.icon-file_upload:before {
  content: "\ea15";
}
.icon-filter_list-default:before {
  content: "\ea16";
}
.icon-folder:before {
  content: "\ea17";
}
.icon-format_bold-default:before {
  content: "\ea18";
}
.icon-format_italic-default:before {
  content: "\ea19";
}
.icon-get_app-default:before {
  content: "\ea1a";
}
.icon-googleplus_reshare-default:before {
  content: "\ea1b";
}
.icon-grade:before {
  content: "\ea1c";
}
.icon-grade-default:before {
  content: "\ea1d";
}
.icon-home:before {
  content: "\ea1e";
}
.icon-insert-drive:before {
  content: "\ea1f";
}
.icon-lock:before {
  content: "\ea20";
}
.icon-menu-close:before {
  content: "\ea21";
}
.icon-menu-open:before {
  content: "\ea22";
}
.icon-more_horiz:before {
  content: "\ea23";
}
.icon-more_horiz-1:before {
  content: "\ea24";
}
.icon-more_vert-default:before {
  content: "\ea25";
}
.icon-not_interested-default:before {
  content: "\ea26";
}
.icon-open_in_new-default:before {
  content: "\ea27";
}
.icon-outline-arrow_downward-24px-default:before {
  content: "\ea28";
}
.icon-outline-arrow_forward-24px-default:before {
  content: "\ea29";
}
.icon-outline-drafts-24px-default:before {
  content: "\ea2a";
}
.icon-outline-error_outline-24px:before {
  content: "\ea2b";
}
.icon-outline-exit_to_app-24px:before {
  content: "\ea2c";
}
.icon-outline-flag-24px-default:before {
  content: "\ea2d";
}
.icon-outline-inbox-24px-default:before {
  content: "\ea2e";
}
.icon-outline-keyboard_arrow_down-24px-default:before {
  content: "\ea2f";
}
.icon-outline-keyboard_arrow_left:before {
  content: "\ea30";
}
.icon-outline-keyboard_arrow_left-24px-default:before {
  content: "\ea31";
}
.icon-outline-keyboard_arrow_right:before {
  content: "\ea32";
}
.icon-outline-keyboard_arrow_right-24px-default:before {
  content: "\ea33";
}
.icon-outline-keyboard_arrow_up-24px-default:before {
  content: "\ea34";
}
.icon-outline-keyboard_backspace-24px-default:before {
  content: "\ea35";
}
.icon-outline-notifications-24px:before {
  content: "\ea36";
}
.icon-outline-payment-24px-default:before {
  content: "\ea37";
}
.icon-outline-people_outline-24px-default:before {
  content: "\ea38";
}
.icon-outline-person_add-24px:before {
  content: "\ea39";
}
.icon-outline-person-24px-default:before {
  content: "\ea3a";
}
.icon-outline-receipt-24px-default:before {
  content: "\ea3b";
}
.icon-outline-refresh-24px-default:before {
  content: "\ea3c";
}
.icon-outline-send-24px-default:before {
  content: "\ea3d";
}
.icon-outline-web_asset-24px-default:before {
  content: "\ea3e";
}
.icon-reply_all-default:before {
  content: "\ea3f";
}
.icon-reply-default:before {
  content: "\ea40";
}
.icon-search-default:before {
  content: "\ea41";
}
.icon-send-default:before {
  content: "\ea42";
}
.icon-settings-default:before {
  content: "\ea43";
}
.icon-share-default:before {
  content: "\ea44";
}
.icon-star_border-default:before {
  content: "\ea45";
}
.icon-view_agenda-default:before {
  content: "\ea46";
}
.icon-view_day-default:before {
  content: "\ea47";
}
.icon-view_module-default-Copy-1:before {
  content: "\ea48";
}
.icon-view_module-default:before {
  content: "\ea49";
}
.icon-view_week-default:before {
  content: "\ea4a";
}
.icon-access_time-default:before {
  content: "\ea4b";
}
.icon-add-default:before {
  content: "\ea4c";
}
.icon-arrow_drop_down:before {
  content: "\ea4d";
}
.icon-arrow_drop_down-default:before {
  content: "\ea4e";
}
.icon-attach_file-default:before {
  content: "\ea4f";
}
.icon-attach_money-default:before {
  content: "\ea50";
}
.icon-bar_chart:before {
  content: "\ea51";
}
.icon-baseline-add_photo_alternate-24px-default:before {
  content: "\ea52";
}
.icon-baseline-edit-24px-default:before {
  content: "\ea53";
}
.icon-baseline-mail_outline-24px:before {
  content: "\ea54";
}
.icon-calendar:before {
  content: "\ea55";
}
.icon-cancel-24px:before {
  content: "\ea56";
}
.icon-outline-keyboard_arrow_right1:before {
  content: "\ea57";
}
.icon-star:before {
  content: "\ea58";
}
.icon-warning:before {
  content: "\ea59";
}
.icon-alert:before {
  content: "\ea5a";
}
.icon-check_circle:before {
  content: "\ea5b";
}
.icon-close:before {
  content: "\ea5c";
}
.icon-first_page:before {
  content: "\ea5d";
}
.icon-last_page:before {
  content: "\ea5e";
}
.icon-outline-keyboard_arrow_left1:before {
  content: "\ea5f";
}
.icon-logo:before {
  content: "\e900";
  color: #fff;
}
.icon-arrow-down:before {
  content: "\e901";
}
.icon-arrow-down-circle:before {
  content: "\e902";
}
.icon-arrow-down-left:before {
  content: "\e903";
}
.icon-arrow-down-right:before {
  content: "\e904";
}
.icon-arrow-left:before {
  content: "\e905";
}
.icon-arrow-left-circle:before {
  content: "\e906";
}
.icon-arrow-right:before {
  content: "\e907";
}
.icon-arrow-right-circle:before {
  content: "\e908";
}
.icon-arrow-up:before {
  content: "\e909";
}
.icon-arrow-up-circle:before {
  content: "\e90a";
}
.icon-arrow-up-left:before {
  content: "\e90b";
}
.icon-arrow-up-right:before {
  content: "\e90c";
}
.icon-at-sign:before {
  content: "\e90d";
}
.icon-award:before {
  content: "\e90e";
}
.icon-bar-chart:before {
  content: "\e90f";
}
.icon-bar-chart-2:before {
  content: "\e910";
}
.icon-battery:before {
  content: "\e911";
}
.icon-battery-charging:before {
  content: "\e912";
}
.icon-bell:before {
  content: "\e913";
}
.icon-bell-off:before {
  content: "\e914";
}
.icon-bluetooth:before {
  content: "\e915";
}
.icon-bold:before {
  content: "\e916";
}
.icon-book:before {
  content: "\e917";
}
.icon-bookmark:before {
  content: "\e918";
}
.icon-book-open:before {
  content: "\e919";
}
.icon-box:before {
  content: "\e91a";
}
.icon-briefcase:before {
  content: "\e91b";
}
.icon-calendar1:before {
  content: "\e91c";
}
.icon-camera:before {
  content: "\e91d";
}
.icon-camera-off:before {
  content: "\e91e";
}
.icon-cast:before {
  content: "\e91f";
}
.icon-check:before {
  content: "\e920";
}
.icon-check-circle:before {
  content: "\e921";
}
.icon-check-square:before {
  content: "\e922";
}
.icon-chevron-down:before {
  content: "\e923";
}
.icon-chevron-left:before {
  content: "\e924";
}
.icon-chevron-right:before {
  content: "\e925";
}
.icon-chevrons-down:before {
  content: "\e926";
}
.icon-chevrons-left:before {
  content: "\e927";
}
.icon-chevrons-right:before {
  content: "\e928";
}
.icon-chevrons-up:before {
  content: "\e929";
}
.icon-chevron-up:before {
  content: "\e92a";
}
.icon-chrome:before {
  content: "\e92b";
}
.icon-circle:before {
  content: "\e92c";
}
.icon-clipboard:before {
  content: "\e92d";
}
.icon-clock:before {
  content: "\e92e";
}
.icon-cloud:before {
  content: "\e92f";
}
.icon-cloud-drizzle:before {
  content: "\e930";
}
.icon-cloud-lightning:before {
  content: "\e931";
}
.icon-cloud-off:before {
  content: "\e932";
}
.icon-cloud-rain:before {
  content: "\e933";
}
.icon-cloud-snow:before {
  content: "\e934";
}
.icon-code:before {
  content: "\e935";
}
.icon-codepen:before {
  content: "\e936";
}
.icon-command:before {
  content: "\e937";
}
.icon-compass:before {
  content: "\e938";
}
.icon-copy:before {
  content: "\e939";
}
.icon-corner-down-left:before {
  content: "\e93a";
}
.icon-corner-down-right:before {
  content: "\e93b";
}
.icon-corner-left-down:before {
  content: "\e93c";
}
.icon-corner-left-up:before {
  content: "\e93d";
}
.icon-corner-right-down:before {
  content: "\e93e";
}
.icon-corner-right-up:before {
  content: "\e93f";
}
.icon-corner-up-left:before {
  content: "\e940";
}
.icon-corner-up-right:before {
  content: "\e941";
}
.icon-cpu:before {
  content: "\e942";
}
.icon-credit-card:before {
  content: "\e943";
}
.icon-crop:before {
  content: "\e944";
}
.icon-crosshair:before {
  content: "\e945";
}
.icon-database:before {
  content: "\e946";
}
.icon-delete1:before {
  content: "\e947";
}
.icon-disc:before {
  content: "\e948";
}
.icon-dollar-sign:before {
  content: "\e949";
}
.icon-download:before {
  content: "\e94a";
}
.icon-download-cloud:before {
  content: "\e94b";
}
.icon-droplet:before {
  content: "\e94c";
}
.icon-edit:before {
  content: "\e94d";
}
.icon-edit-2:before {
  content: "\e94e";
}
.icon-edit-3:before {
  content: "\e94f";
}
.icon-external-link:before {
  content: "\e950";
}
.icon-eye:before {
  content: "\e951";
}
.icon-eye-off:before {
  content: "\e952";
}
.icon-facebook:before {
  content: "\e953";
}
.icon-fast-forward:before {
  content: "\e954";
}
.icon-feather:before {
  content: "\e955";
}
.icon-file:before {
  content: "\e956";
}
.icon-file-minus:before {
  content: "\e957";
}
.icon-file-plus:before {
  content: "\e958";
}
.icon-file-text:before {
  content: "\e959";
}
.icon-film:before {
  content: "\e95a";
}
.icon-filter:before {
  content: "\e95b";
}
.icon-flag:before {
  content: "\e95c";
}
.icon-folder1:before {
  content: "\e95d";
}
.icon-folder-minus:before {
  content: "\e95e";
}
.icon-folder-plus:before {
  content: "\e95f";
}
.icon-gift:before {
  content: "\e960";
}
.icon-git-branch:before {
  content: "\e961";
}
.icon-git-commit:before {
  content: "\e962";
}
.icon-github:before {
  content: "\e963";
}
.icon-gitlab:before {
  content: "\e964";
}
.icon-git-merge:before {
  content: "\e965";
}
.icon-git-pull-request:before {
  content: "\e966";
}
.icon-globe:before {
  content: "\e967";
}
.icon-grid:before {
  content: "\e968";
}
.icon-hard-drive:before {
  content: "\e969";
}
.icon-hash:before {
  content: "\e96a";
}
.icon-headphones:before {
  content: "\e96b";
}
.icon-heart:before {
  content: "\e96c";
}
.icon-help-circle:before {
  content: "\e96d";
}
.icon-home1:before {
  content: "\e96e";
}
.icon-image:before {
  content: "\e96f";
}
.icon-inbox:before {
  content: "\e970";
}
.icon-info:before {
  content: "\e971";
}
.icon-instagram:before {
  content: "\e972";
}
.icon-italic:before {
  content: "\e973";
}
.icon-layers:before {
  content: "\e974";
}
.icon-layout:before {
  content: "\e975";
}
.icon-life-buoy:before {
  content: "\e976";
}
.icon-link:before {
  content: "\e977";
}
.icon-link-2:before {
  content: "\e978";
}
.icon-linkedin:before {
  content: "\e979";
}
.icon-list:before {
  content: "\e97a";
}
.icon-loader:before {
  content: "\e97b";
}
.icon-lock1:before {
  content: "\e97c";
}
.icon-log-in:before {
  content: "\e97d";
}
.icon-log-out:before {
  content: "\e97e";
}
.icon-mail:before {
  content: "\e97f";
}
.icon-map:before {
  content: "\e980";
}
.icon-map-pin:before {
  content: "\e981";
}
.icon-maximize:before {
  content: "\e982";
}
.icon-maximize-2:before {
  content: "\e983";
}
.icon-menu:before {
  content: "\e984";
}
.icon-message-circle:before {
  content: "\e985";
}
.icon-message-square:before {
  content: "\e986";
}
.icon-mic:before {
  content: "\e987";
}
.icon-mic-off:before {
  content: "\e988";
}
.icon-minimize:before {
  content: "\e989";
}
.icon-minimize-2:before {
  content: "\e98a";
}
.icon-minus:before {
  content: "\e98b";
}
.icon-minus-circle:before {
  content: "\e98c";
}
.icon-minus-square:before {
  content: "\e98d";
}
.icon-monitor:before {
  content: "\e98e";
}
.icon-moon:before {
  content: "\e98f";
}
.icon-more-horizontal:before {
  content: "\e990";
}
.icon-more-vertical:before {
  content: "\e991";
}
.icon-move:before {
  content: "\e992";
}
.icon-music:before {
  content: "\e993";
}
.icon-navigation:before {
  content: "\e994";
}
.icon-navigation-2:before {
  content: "\e995";
}
.icon-octagon:before {
  content: "\e996";
}
.icon-package:before {
  content: "\e997";
}
.icon-paperclip:before {
  content: "\e998";
}
.icon-pause:before {
  content: "\e999";
}
.icon-pause-circle:before {
  content: "\e99a";
}
.icon-percent:before {
  content: "\e99b";
}
.icon-phone:before {
  content: "\e99c";
}
.icon-phone-call:before {
  content: "\e99d";
}
.icon-phone-forwarded:before {
  content: "\e99e";
}
.icon-phone-incoming:before {
  content: "\e99f";
}
.icon-phone-missed:before {
  content: "\e9a0";
}
.icon-phone-off:before {
  content: "\e9a1";
}
.icon-phone-outgoing:before {
  content: "\e9a2";
}
.icon-pie-chart:before {
  content: "\e9a3";
}
.icon-play:before {
  content: "\e9a4";
}
.icon-play-circle:before {
  content: "\e9a5";
}
.icon-plus:before {
  content: "\e9a6";
}
.icon-plus-circle:before {
  content: "\e9a7";
}
.icon-plus-square:before {
  content: "\e9a8";
}
.icon-pocket:before {
  content: "\e9a9";
}
.icon-power:before {
  content: "\e9aa";
}
.icon-printer:before {
  content: "\e9ab";
}
.icon-radio:before {
  content: "\e9ac";
}
.icon-refresh-ccw:before {
  content: "\e9ad";
}
.icon-refresh-cw:before {
  content: "\e9ae";
}
.icon-repeat:before {
  content: "\e9af";
}
.icon-rewind:before {
  content: "\e9b0";
}
.icon-rotate-ccw:before {
  content: "\e9b1";
}
.icon-rotate-cw:before {
  content: "\e9b2";
}
.icon-rss:before {
  content: "\e9b3";
}
.icon-save:before {
  content: "\e9b4";
}
.icon-scissors:before {
  content: "\e9b5";
}
.icon-search:before {
  content: "\e9b6";
}
.icon-send:before {
  content: "\e9b7";
}
.icon-server:before {
  content: "\e9b8";
}
.icon-settings:before {
  content: "\e9b9";
}
.icon-share:before {
  content: "\e9ba";
}
.icon-share-2:before {
  content: "\e9bb";
}
.icon-shield:before {
  content: "\e9bc";
}
.icon-shield-off:before {
  content: "\e9bd";
}
.icon-shopping-bag:before {
  content: "\e9be";
}
.icon-shopping-cart:before {
  content: "\e9bf";
}
.icon-shuffle:before {
  content: "\e9c0";
}
.icon-sidebar:before {
  content: "\e9c1";
}
.icon-sidebar-r:before {
  content: "\ea67";
}
.icon-skip-back:before {
  content: "\e9c2";
}
.icon-skip-forward:before {
  content: "\e9c3";
}
.icon-slack:before {
  content: "\e9c4";
}
.icon-slash:before {
  content: "\e9c5";
}
.icon-sliders:before {
  content: "\e9c6";
}
.icon-smartphone:before {
  content: "\e9c7";
}
.icon-speaker:before {
  content: "\e9c8";
}
.icon-square:before {
  content: "\e9c9";
}
.icon-star1:before {
  content: "\e9ca";
}
.icon-stop-circle:before {
  content: "\e9cb";
}
.icon-sun:before {
  content: "\e9cc";
}
.icon-sunrise:before {
  content: "\e9cd";
}
.icon-sunset:before {
  content: "\e9ce";
}
.icon-tablet:before {
  content: "\e9cf";
}
.icon-tag:before {
  content: "\e9d0";
}
.icon-target:before {
  content: "\e9d1";
}
.icon-terminal:before {
  content: "\e9d2";
}
.icon-thermometer:before {
  content: "\e9d3";
}
.icon-thumbs-down:before {
  content: "\e9d4";
}
.icon-thumbs-up:before {
  content: "\e9d5";
}
.icon-toggle-left:before {
  content: "\e9d6";
}
.icon-toggle-right:before {
  content: "\e9d7";
}
.icon-trash:before {
  content: "\e9d8";
}
.icon-trash-2:before {
  content: "\e9d9";
}
.icon-trending-down:before {
  content: "\e9da";
}
.icon-trending-up:before {
  content: "\e9db";
}
.icon-triangle:before {
  content: "\e9dc";
}
.icon-truck:before {
  content: "\e9dd";
}
.icon-tv:before {
  content: "\e9de";
}
.icon-twitter:before {
  content: "\e9df";
}
.icon-type:before {
  content: "\e9e0";
}
.icon-umbrella:before {
  content: "\e9e1";
}
.icon-underline:before {
  content: "\e9e2";
}
.icon-unlock:before {
  content: "\e9e3";
}
.icon-upload:before {
  content: "\e9e4";
}
.icon-upload-cloud:before {
  content: "\e9e5";
}
.icon-user:before {
  content: "\e9e6";
}
.icon-user-check:before {
  content: "\e9e7";
}
.icon-user-minus:before {
  content: "\e9e8";
}
.icon-user-plus:before {
  content: "\e9e9";
}
.icon-users:before {
  content: "\e9ea";
}
.icon-user-x:before {
  content: "\e9eb";
}
.icon-video:before {
  content: "\e9ec";
}
.icon-video-off:before {
  content: "\e9ed";
}
.icon-voicemail:before {
  content: "\e9ee";
}
.icon-volume:before {
  content: "\e9ef";
}
.icon-volume-1:before {
  content: "\e9f0";
}
.icon-volume-2:before {
  content: "\e9f1";
}
.icon-volume-x:before {
  content: "\e9f2";
}
.icon-watch:before {
  content: "\e9f3";
}
.icon-wifi:before {
  content: "\e9f4";
}
.icon-wifi-off:before {
  content: "\e9f5";
}
.icon-wind:before {
  content: "\e9f6";
}
.icon-x:before {
  content: "\e9f7";
}
.icon-x-circle:before {
  content: "\e9f8";
}
.icon-x-square:before {
  content: "\e9f9";
}
.icon-youtube:before {
  content: "\e9fa";
}
.icon-zap:before {
  content: "\e9fb";
}
.icon-zap-off:before {
  content: "\e9fc";
}
.icon-zoom-in:before {
  content: "\e9fd";
}
.icon-zoom-out:before {
  content: "\e9fe";
}
.icon-activity:before {
  content: "\e9ff";
}
.icon-airplay:before {
  content: "\ea00";
}
.icon-alert-circle:before {
  content: "\ea01";
}
.icon-alert-octagon:before {
  content: "\ea02";
}
.icon-alert-triangle:before {
  content: "\ea03";
}
.icon-align-center:before {
  content: "\ea04";
}
.icon-align-justify:before {
  content: "\ea05";
}
.icon-align-left:before {
  content: "\ea06";
}
.icon-align-right:before {
  content: "\ea07";
}
.icon-anchor:before {
  content: "\ea08";
}
.icon-aperture:before {
  content: "\ea09";
}
.icon-archive:before {
  content: "\ea0a";
}
